import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Swal from "sweetalert2";
import "./Information.css";
import MobileSelect from "mobile-select";
import DataContext from "../DataContext"

function SearchProduct() {
  const Brand = process.env.REACT_APP_BRAND.toLocaleLowerCase();
  const navigate = useNavigate();
  const {setAge , setGender , dataPromotion , setDataPromotion} = useContext(DataContext)
  const tirggerRef = useRef(null);
  const [selectedVal, setSelectedVal] = useState(20);
  let msInstance = null;

  const [coltext, setColtext] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    gender: "",
    age: selectedVal,
    weight: "",
  });

  useEffect(() => {
    if (Brand === "spa") {
      setColtext("#007c83");
    } else {
      setColtext("#00528d");
    }
    let ages = [];

    for (var i = 1; i <= 100; i++) {
      ages.push(i);
    }
    if (!msInstance) {
      msInstance = new MobileSelect({
        wheels: [{ data: ages }],
        ensureBtnText: "ตกลง",
        cancelBtnText: "ยกเลิก",
        trigger: tirggerRef.current,
        triggerDisplayValue: false, // If you don't want to overwrite the HTML inside the trigger, you need to set this to false
        onChange: (data) => {
          var arrayOfNumbers = data.map(Number);
          setSelectedVal(arrayOfNumbers[0]);
          setAge(JSON.stringify(arrayOfNumbers[0]))
          setData((prevProps) => ({
            ...prevProps,
            age: JSON.stringify(arrayOfNumbers[0]),
          }));
          setDataPromotion((prevProps) => ({
            ...prevProps,
            age: JSON.stringify(arrayOfNumbers[0]),
          }));
        },
      });
    }
    return () => {
      msInstance?.destroy();
      msInstance = null;
    };
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
    if(name === "gender"){
      setDataPromotion((prevProps) => ({
        ...prevProps,
        [name]: value,
      }));
    }
  };

  const onValueChange = (e) => {
    const { name, value } = e.target;
    if(name === "PBF"){
      let arr = value.split("-")
      setDataPromotion((prevProps) => ({
        ...prevProps,
        ["pbf_from"]: arr[0],
        ["pbf_to"]: arr[1],
      }));
    }else{
      setDataPromotion((prevProps) => ({
        ...prevProps,
        [name]: value,
      }));
    }
  };

  const submitForm = (e) => {
    
    e.preventDefault();

    if (dataPromotion.pbf_from === ""){
      Swal.fire({
        icon: 'warning',
        title: 'กรุณาเลือกไขมันในร่างกาย',
        showConfirmButton: false,
        timer: 2000
      })
      return false
    }
    if (dataPromotion.tbw === ""){
      Swal.fire({
        icon: 'warning',
        title: 'กรุณาเลือกน้ำในร่างกาย',
        showConfirmButton: false,
        timer: 2000
      })
      return false
    }
    if (dataPromotion.all_body_part === ""){
      Swal.fire({
        icon: 'warning',
        title: 'กรุณาเลือกสัดส่วน',
        showConfirmButton: false,
        timer: 2000
      })
      return false
    }
    setGender(data.gender)
    setIsLoading(true);
    let timerInterval
    Swal.fire({
      title: 'กำลังค้นหาข้อมูล',
      timer: 1200,
      didOpen: () => {
        Swal.showLoading()
        const b = Swal.getHtmlContainer().querySelector('b')
      
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
        navigate(`/productlist/A`,{
          state: dataPromotion
        })
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'กรุณาลองใหม่อีกครั้ง',
      })
      setIsLoading(false)
    })   
  };

  return (
    <section className="get-in-touch">
      <h1 className="title" style={{ color: coltext }}>
        <img
          src={require(`../logo/${Brand}_logo.png`)}
          style={{ width: "300px", height: "80px" }}
          alt="Logo"
        />
      </h1>
      <form className="contact-form row" onSubmit={submitForm}>               
        <div className="form-field col-12 radio_input">
          <div className="label mb-3" htmlFor="tbw" style={{ color: coltext }}>
            เพศ 
          </div>    
          <div className="row">
            <div className="col-6">
              <input type="radio" name="gender" value="M" id="radio-16" defaultChecked={dataPromotion.gender === "M"} onChange={handleInputChange}/>
              <label htmlFor="radio-16">ชาย</label>
            </div>
            <div className="col-6">
              <input type="radio" name="gender" value="F" id="radio-17" defaultChecked={dataPromotion.gender === "F"} onChange={handleInputChange}/>
              <label htmlFor="radio-17">หญิง</label>
            </div>
          </div>
        </div>
        <div className="form-field col-12 radio_input">            
            <div className="label mb-3" htmlFor="PBF" style={{ color: coltext }}>
              ไขมันในร่างกาย (PBF)
            </div>   
            {dataPromotion.gender === "M" ?
              <div className="row">
                <div className="col-6">
                  <input type="radio" name="PBF" value="14-15" id="radio-1" defaultChecked={dataPromotion.pbf_from === "14"} onChange={onValueChange}/>
                  <label htmlFor="radio-1">14-15%</label>
                </div> 
                <div className="col-6">
                  <input type="radio" name="PBF" value="20-22" id="radio-2" defaultChecked={dataPromotion.pbf_from === "20"} onChange={onValueChange}/>
                  <label htmlFor="radio-2">20-22%</label>
                </div>
                <div className="col-6">
                  <input type="radio" name="PBF" value="30-32" id="radio-3" defaultChecked={dataPromotion.pbf_from === "30"} onChange={onValueChange}/>
                  <label htmlFor="radio-3">30-32%</label>
                </div> 
                <div className="col-6">      
                  <input type="radio" name="PBF" value="33-100" id="radio-4" defaultChecked={dataPromotion.pbf_from === "33"} onChange={onValueChange}/>
                  <label htmlFor="radio-4">32% ขึ้นไป</label>                
                </div>
              </div> 
            :
              <div className="row">
                <div className="col-6">
                  <input type="radio" name="PBF" value="17-18" id="radio-5" defaultChecked={dataPromotion.pbf_from === "17"} onChange={onValueChange}/>
                  <label htmlFor="radio-5">17-18%</label> 
                </div>
                <div className="col-6">
                  <input type="radio" name="PBF" value="20-21" id="radio-6" defaultChecked={dataPromotion.pbf_from === "20"} onChange={onValueChange}/>
                  <label htmlFor="radio-6">20-21%</label>  
                </div>
                <div className="col-6">
                  <input type="radio" name="PBF" value="25-26" id="radio-7" defaultChecked={dataPromotion.pbf_from === "25"} onChange={onValueChange}/>
                  <label htmlFor="radio-7">25-26%</label> 
                </div> 
                <div className="col-6">
                  <input type="radio" name="PBF" value="27-100" id="radio-8" defaultChecked={dataPromotion.pbf_from === "27"} onChange={onValueChange}/>
                  <label htmlFor="radio-8">27% ขึ้นไป</label>  
                </div>
              </div>
            }
            
        </div>
        <div className="col-12 radio_input">
            <div className="label mb-3" htmlFor="tbw" style={{ color: coltext }}>
              น้ำในร่างกาย(TBW) 
            </div>    
            <div className="row">
              <div className="col-6">
                <input type="radio" name="tbw" value="Y" id="radio-12" defaultChecked={dataPromotion.tbw === "Y"} onChange={onValueChange}/>
                <label htmlFor="radio-12">บวมน้ำ</label>
              </div>
              <div className="col-6">
                <input type="radio" name="tbw" value="N" id="radio-13" defaultChecked={dataPromotion.tbw === "N"} onChange={onValueChange}/>
                <label htmlFor="radio-13">ไม่บวมน้ำ</label>
              </div>
            </div>
        </div>
        <div className="form-field col-12 radio_input">
            <div className="label mb-3" htmlFor="all_body_part" style={{ color: coltext }}>
              ระบุสัดส่วน
            </div>    
            <div className="row">
              <div className="col-6">
                <input type="radio" name="all_body_part" value="Y" id="radio-14" defaultChecked={dataPromotion.all_body_part === "Y"} onChange={onValueChange}/>
                <label htmlFor="radio-14">ลดทั้งตัว</label>
              </div>
              <div className="col-6">
                <input type="radio" name="all_body_part" value="N" id="radio-15" defaultChecked={dataPromotion.all_body_part === "N"} onChange={onValueChange}/>
                <label htmlFor="radio-15">ลดเฉพาะส่วน</label>
              </div>
            </div>
        </div>
        <div className="form-field col-12">
            <label className="label" htmlFor="age" style={{ color: coltext }}>
                อายุ
            </label>
            <div className="ms-default-trigger ageclass" ref={tirggerRef}>
                <div className="">
                    {selectedVal ? dataPromotion.age + " ปี" : "กรุณาเลือก..."}
                </div>               
            </div>
        </div>        
        <div className="form-field col-12 d-flex justify-content-between">
          {!isLoading ? (
            <input
              className="submit-btn"
              style={{ backgroundColor: coltext }}
              type="submit"
              value="ค้นหา"
            />
          ) : (
            <button
              className="submit-btn"
              style={{ backgroundColor: coltext }}
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          )}

          <Link to="/">
            <input
              className="submit-btn bg-danger"
              type="submit"
              value="ยกเลิก"
              disabled={isLoading}
            />
          </Link>
        </div>
      </form>
    </section>
  );
}

export default SearchProduct;
