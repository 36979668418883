import "./Productdetail.css"
import {useParams , useNavigate  } from "react-router-dom";
import { useState , useEffect , useContext} from "react";
import DataContext from "../DataContext"
import { v4 as uuid } from 'uuid';
import FlyingButton from 'react-flying-item'
import axios from "axios";

function Productdetail() {

    let navigate  = useNavigate ();
    
    const {select , setSelect , uidm} = useContext(DataContext);
    const {id} = useParams();
    const [product,setProduct] = useState([]);
    const [coltext, setColtext] = useState("");
    

    useEffect(() => {

        const Brand = process.env.REACT_APP_BRAND.toLocaleLowerCase();
        
        if(Brand === "spa"){
            setColtext("#007c83")
        }else{
            setColtext("#00528d")
        }

        var config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_API_URL + "/get_promotion_detail/" + id,
            headers: { 
                'api_key': process.env.REACT_APP_API_KEY, 
            }
        };


        async function fetchMyAPI() {
            let response = await axios(config)
            setProduct(response.data)            
        }  
        fetchMyAPI() 
        .catch(function (error) {
            console.log(error);
        });

    },[]);   

    const handleInputChange = () => {
        const itemData = {
            UUid:uuid(),
            PROMOTION_CODE:product.product.PROMOTION_CODE,
            PROMOTION_NAME:product.product.PROMOTION_NAME,
            uid_m_criteria:uidm.uid_m_criteria,
            uid_m_promotion_set:uidm.uid_m_promotion_set,
            uid_m_promotion:product.product.UID,
        }

        setSelect([...select,itemData])
    }

    const Media = ({data}) => {

        if(data.MEDIA_TYPE === "IMAGE"){
            return (
                <div className="col-12 pb-2">
                    <img src={data.URL} alt={data.URL}  width="720" height="480"/>  
                </div>
            )
           
        }else if (data.MEDIA_TYPE === "VDO"){
            let result = `${process.env.REACT_APP_API_URL}/stream/${data.UID_M_PROMOTION}/${data.SEQ}`
            return(
                <div className="col-12 mb-2">
                    {/* <video controls preload="auto" src={result} width="50%" height="50%"></video> */}
                    <video  width="100%" height="auto" controls>
                        <source src={result}/>
                    </video>
                </div>
            )
        }
    }

    

    if(product.length === 0){
        return (
            <div className="container product_wrapper">  
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status"></div>
                </div>
            </div>
        )
    }

    return (
        <>
            {product && 
                <div className="container product_wrapper">        
                    <div>
                        <div className = "card-wrapper">
                            <div className = "card_detail row">                               
                                <div className = "product-content col-12">
                                    <h2 className = "product-title">{product.product.PROMOTION_NAME}</h2>
                                    <br/>
                                    <div className = "product-detail">
                                        <h2>รายละเอียด: </h2>
                                        <span>{product.product.PROMOTION_DETAIL}</span>
                                    </div>     
                                </div>                               
                                <div className = "col-12">                                   
                                    {product.media.map((x, index) => {
                                        return (
                                            <Media data={x} key={index}/>
                                        )})
                                    }                                 
                                </div>        
                            </div>
                        </div>
                        
                    </div>
                    <div className="btn_wrapper mt-5">
                        <button type="button" className="submit-btn d-block" style={{backgroundColor: coltext}} onClick={() => navigate(-1)}>ย้อนกลับ</button>
                        <FlyingButton  src={product.media[0].URL} animationDuration={1.2} targetTop={'0%'} targetLeft={'82%'} flyingItemStyling={{borderRadius: '0rem', width: '8rem'}}>
                            <div type="button" className="submit-btn d-block" style={{backgroundColor: coltext}} onClick={handleInputChange}>เพิ่มรายการที่สนใจ</div>
                        </FlyingButton>                   
                    </div>
                </div>  
            }
        </>
        
    );
  

}

export default Productdetail;