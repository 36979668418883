import { useLocation, useNavigate,Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "./ProductList.css"
import ProductListcomponent from "../components/Productcomponents";

function ProductList() {
    const {id} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const Brand = process.env.REACT_APP_BRAND.toLocaleLowerCase();
    const [coltext, setColtext] = useState("");
    const [promotion,setPromotion] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    
    useEffect(() => {         
        if(Brand === "spa"){
            setColtext("#007c83")
        }else{
            setColtext("#00528d")
        } 
        if(!id){
            return navigate(`/information`)
        }   
        
        var urlapi = "";
        var setMethod = ""
        
        if(id === "A"){
            var data = JSON.stringify(location.state);
            urlapi += "/get_promotions" ;
            setMethod += 'post'
        }else{
            urlapi += "/get_promotions_by_gender/"+id;
            setMethod += 'get'
        }

        var config = {
            method: setMethod,
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_API_URL + urlapi,
            headers: { 
                    'api_key': process.env.REACT_APP_API_KEY, 
                    'Content-Type': 'application/json'
                },
            data : data
        };
        axios(config)
        .then(function (response) {
            const key = 'UID_M_PROMOTION_SET';
            const arrayUniqueByKey = [...new Map(response.data.map(item => [item[key], item])).values()];
            setPromotion(arrayUniqueByKey); 
            setFoundData(arrayUniqueByKey) 
            setIsLoading(true)               
        })
        .catch(function (error) {
            Swal.fire({
                icon: 'warning',
                title: 'กรุณาลองใหม่อีกครั้ง',
                showConfirmButton: false,
                timer: 2000
            })
            navigate(`/`)
        });       

    }, []);

    const [name, setName] = useState('');
    const [foundData, setFoundData] = useState();

    const filter = (e) => {
        const keyword = e.target.value;  
        if (keyword !== '') {
            const results = promotion.filter((x) => {
                return x.SET_CODE.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundData(results);
        } else {
            setFoundData(promotion);
          // If the text field is empty, show all users
        }
    
        setName(keyword);
    };

    return (
        <div className="container product_wrapper">
            <h3>รายการคอร์สแนะนำ</h3>  
            <div className="row"> 
                <div className="py-3">
                    <form className="form-inline">
                        <div className="input-group">
                            
                            <input
                                type="search"
                                value={name}
                                onChange={filter}
                                className="form-control"
                                placeholder= "ค้นหา"
                            />
                        </div>
                    </form>               
                </div>
            </div>
            <div className="row">
                <Link className="py-3">
                    <button type="button" className="submit-btn" style={{backgroundColor: coltext}} onClick={() => navigate(-1)}>ย้อนกลับ</button>
                </Link> 
            </div>         
            <section className="row">              
                {isLoading ? foundData.length === 0 ? 
                    <>
                        <div style={{textAlign: "center"}} className="py-5">ไม่พบรายการ</div> 
                        <Link>
                            <button type="button" className="submit-btn" style={{backgroundColor: coltext}} onClick={() => navigate(-1)}>ย้อนกลับ</button>
                        </Link>
                    </>
                :
                    <>
                        <ProductListcomponent data={foundData}/> 
                        <Link>
                            <button type="button" className="submit-btn" style={{backgroundColor: coltext}} onClick={() => navigate(-1)}>ย้อนกลับ</button>
                        </Link>  
                    </>
                :                
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status"></div>
                    </div>
                }
            </section>           
        </div>
    );
  

}

export default ProductList;