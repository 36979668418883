import './ProductListcomponent.css';
import { useContext } from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import DataContext from "../DataContext"



function ProductListcomponent({data}) {

    const {setUidm} = useContext(DataContext);

    const onSavevalue = (CRITERIA , SET ) => {
        setUidm({
            uid_m_criteria:CRITERIA,
            uid_m_promotion_set:SET,
            // uid_m_promotion:""
        })
    }


    return (   
        <>
            {data.map(x => {
                const urlDetail = '/Productset/'
                return (
                    <div className="col-md-6 col-lg-4 p-3 mb-2" key={x.UID_M_PROMOTION_SET}>
                        <img
                            src={x.IMG_COVER}
                            style={{ width: "100%", height: "auto"}}
                            alt={x.SET_CODE}
                        />
                        <div className="List_header_com">{x.SET_CODE}</div>    
                        <Link to={urlDetail + x.UID_M_PROMOTION_SET} onClick={() => onSavevalue(x.UID_M_CRITERIA , x.UID_M_PROMOTION_SET )} className='List_link List_more d-flex justify-content-center'>
                            <div>
                                <span>รายละเอียดโปรโมชั่น  </span> 
                                <FiChevronRight/>
                            </div>
                        </Link>                                                         
                    </div> 
                )          
            })}
        </>     
    );
  

}

export default ProductListcomponent;