import Home from './Pages/Home';
import Summary from './Pages/Summary';
import Information from './Pages/Information';
import ProductList from './Pages/ProductList';
import Productdetail from './Pages/Productdetail';
import Productset from './Pages/Productset';
import SearchProduct from './Pages/SearchProduct';
import DataContext from './DataContext'
import ExamplePDF from './Pages/ExamplePDF';
import { BrowserRouter as Router,Routes,Route} from "react-router-dom";
import Navbar from './components/Navbar';
import { useState } from 'react';

function App() {

  const [select , setSelect] = useState([]);

  const [uidm , setUidm] = useState({
    uid_m_criteria:"",
    uid_m_promotion_set:"",
    uid_m_promotion:""
  });


  const [age, setAge] = useState(20);
  const [gender, setGender] = useState();
  const [dataPromotion, setDataPromotion] = useState({
    gender: "",
    all_body_part: "",
    age: 20,
    pbf_from: "",
    pbf_to: "",
    tbw: "",
  });


  return (
    <DataContext.Provider 
      value={{uidm: uidm,setUidm: setUidm, select: select , setSelect: setSelect , age: age , setAge: setAge , gender: gender , setGender: setGender , dataPromotion: dataPromotion , setDataPromotion : setDataPromotion}}
    >
      <Router>
        <Navbar/>
        <Routes>         
            <Route path="/" element = {<Home/>} />
            <Route path="/search" element={<SearchProduct/>} />  
            <Route path="/information" element={<Information/>} />         
            <Route path="/summary" element={<Summary/>} />         
            <Route path="/examplepdf" element={<ExamplePDF/>} />         
            <Route path="/productlist/:id" element={<ProductList/>} />
            <Route path="/productset/:id" element={<Productset/>} />
            <Route path="/productdetail/:id" element={<Productdetail/>} />
            <Route path='*' element={<Home/>} />
        </Routes>
      </Router>
    </DataContext.Provider>
  );
}

export default App;
