import { useNavigate, useParams, Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { useEffect, useState , useContext} from "react";
import DataContext from "../DataContext"
import axios from "axios";
import "./Productset.css"

function Productset() {

    const {id} = useParams();
    const navigate = useNavigate();
    const Brand = process.env.REACT_APP_BRAND.toLocaleLowerCase();
    const {setUidm} = useContext(DataContext);
    const [coltext, setColtext] = useState("");
    const [data,setdata] = useState([])
    const [isLoading,setIsLoading] = useState(false)

    useEffect(() => {   
        
        if(Brand === "spa"){
            setColtext("#007c83")
        }else{
            setColtext("#00528d")
        }   
        var urlapi ="";
        if(id === "M" || id === "F"){
            urlapi += "/get_promotions_by_gender/"+id;
            setUidm({
                uid_m_criteria:"GENDER",
                uid_m_promotion_set:"PROMOTION_SET_BY_GENDER",
            })
        }else{
            urlapi += "/get_promotion_set/"+id;
        }

        var config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_API_URL + urlapi,
            headers: { 
                'api_key': process.env.REACT_APP_API_KEY, 
                'Content-Type': 'application/json'
            },
        };
        axios(config)
        .then(function (response) {
            setdata(response.data);                                                                         
            setFoundData(response.data)
            setIsLoading(true)
        })
        .catch(function (error) {
            console.log(error);
            navigate(`/`)
        });     

    }, []);

    const [name, setName] = useState('');
    const [foundData, setFoundData] = useState();

    const filter = (e) => {
        const keyword = e.target.value;
    
        if (keyword !== '') {
            const results = data.filter((x) => {
                return x.PROMOTION_NAME.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundData(results);
        } else {
            setFoundData(data);
          // If the text field is empty, show all users
        }
    
        setName(keyword);
    };


    return (
        <div className="container product_wrapper">
            <h3>รายการรักษาใน คอร์ส</h3>
            <div className="row"> 
                <div className="py-3">
                    <form className="form-inline">
                        <div className="input-group">
                            
                            <input
                                type="search"
                                value={name}
                                onChange={filter}
                                className="form-control"
                                placeholder= "ค้นหา"
                            />
                        </div>
                    </form>               
                </div>
            </div>
            <div className="row">
                <Link className="py-2">
                    <button type="button" className="submit-btn" style={{backgroundColor: coltext}} onClick={() => navigate(-1)}>ย้อนกลับ</button>
                </Link> 
            </div>
            <section className="row p-2">              
                {!isLoading ?
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status"></div>
                    </div> 
                    :   
                    foundData.length === 0 ? <p style={{textAlign:'center'}}>ไม่พบรายการ</p> 
                    :
                    <>                               
                        {foundData.map(x => {
                            const urlDetail = '/Productdetail/'
                            return (
                                <div className="col-md-6 col-lg-4 p-3 mb-2" key={x.UID_M_PROMOTION}>
                                    <img
                                        src={x.IMG_COVER}
                                        style={{ width: "100%", height: "auto"}}
                                        alt={x.SET_CODE}
                                        loading="lazy"
                                    />
                                    <div className="List_header">{x.PROMOTION_NAME}</div>    
                                    <div className="List_detail">{x.PROMOTION_DETAIL}</div>  
                                    <Link to={urlDetail + x.UID_M_PROMOTION}className='List_link List_more d-flex justify-content-center'>
                                        <div>                                       
                                            <span>รายละเอียด </span> 
                                            <FiChevronRight/>                                       
                                        </div>  
                                    </Link>                                                         
                                </div> 
                            )          
                        })}
                        <Link>
                            <button type="button" className="submit-btn" style={{backgroundColor: coltext}} onClick={() => navigate(-1)}>ย้อนกลับ</button>
                        </Link>
                    </>
                }
            </section>
              
        </div>
    );
  

}

export default Productset;