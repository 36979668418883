import { useLocation , useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Printer, { print } from 'react-pdf-print'
import {BsPrinterFill} from 'react-icons/bs'
import { FaClipboardList } from "react-icons/fa";
import "./ExamplePDF.css";

function ExamplePDF() {

    const location = useLocation();
    const navigate = useNavigate();
    var d = new Date(); 
    var datetime = d.getHours()+":"+d.getMinutes()
    var currentdate = d.getDate()+"/"+(d.getMonth()+1)+"/"+ (d.getFullYear() + 543)
    const ids = ['1']

    const reset = () => {        
        navigate(`/`)
        window.location.reload();
    }


    return (  
    <><br/><br/>
        <div style={{display:'flex' , justifyContent:'center',paddingTop:'5px'}}>          
            <div>
                <Printer >
                    <div id={ids[0]} style={{ width:'165mm', height: '310mm' ,display:'flex' , justifyContent:'center' }}>
                        <div>
                            <div>
                                <h4>วันที่ {currentdate} เวลา {datetime} น.</h4>
                            </div>                    
                            <div >             
                                <h5> ชื่อ {location.state.FIRST_NAME} {location.state.LAST_NAME}</h5>            
                            </div>           
                            <div >
                                <h5> เพศ {location.state.GENDER} อายุ {location.state.AGE}</h5> 
                            </div>  
                            <div >
                                <h5> Promotion ที่สนใจ </h5>
                            </div>              
                            <div >
                                {location.state.promotions.map((x , index) => {
                                    return (
                                        <h5 key={x.PROMOTION_CODE}>&nbsp; &nbsp; &nbsp; &nbsp;{index + 1}. {x.PROMOTION_NAME}</h5>
                                    )
                                })}                
                            </div>   
                        </div>         
                    </div> 
            </Printer> 
            <div className="d-flex justify-content-between">
                <button type='button'  onClick={() => print(ids)} value='พิมพ์' className="btn btn-primary"><BsPrinterFill/>&nbsp; &nbsp;พิมพ์</button>
                <button type='button'  onClick={reset} value='รายการใหม่' className="btn btn-success"><FaClipboardList/>&nbsp; &nbsp;รายการใหม่</button>
            </div>
          </div>
        </div>
        </>
    );
  

}

export default ExamplePDF;



{/* <>
            <div>
                <Printer>
                    <div  id={ids[0]}  style={{ width:'200mm', height: '300mm' ,padding:'150px'}}>
                        <div>
                            <div>
                                <p>วันที่ {currentdate} เวลา {datetime} น.</p>
                            </div> 
                            <div>
                                <p>ชื่อ {location.state.FIRST_NAME} {location.state.LAST_NAME}</p>
                            </div> 
                            <div>
                                <p>เพศ {location.state.GENDER ==="M" ? "ชาย" : "หญิง"}  อายุ {location.state.AGE} ปี</p>
                            </div> 
                            <div>
                                <p className="text-decoration-underline" style={{fontWeight: 600}}>Promotion ที่สนใจ</p>
                            </div>             
                            {location.state.promotions.map((x ,index) => {
                                return (
                                    <div key={x.PROMOTION_CODE}> &nbsp; &nbsp; &nbsp;
                                        {index + 1} . {x.PROMOTION_NAME}
                                    </div>
                                )
                            })}                          
                        </div>
                    </div>                 
                </Printer>   
                <input type='button' onClick={() => print(ids)} value='พิมพ์' /> 
            </div>
        </> */}