import './Home.css'
import { Link } from 'react-router-dom'

function Home () {

      
  return (
        <div>
            <div className="wrapper">                 
                <div className="side left">
                    
                    <div className="image leftman"></div>
                    <div className="caption">
                        <Link to="/productset/M" style={{ textDecoration: 'none' }}>
                            <h1>Man</h1>
                        </Link>                         
                        {/* <div className="bttn" >BODY</div>
                        <div className="bttn" >SKIN</div>
                        <div className="bttn" >HAIR</div> */}
                    </div>
                </div>
                <div className="side right">
                    <div className="image rightwoman"></div>
                    <div className="caption">
                        <Link to="/productset/F" style={{ textDecoration: 'none' }}>
                            <h1>Woman</h1>
                        </Link>  
                        {/* <div className="bttn" >BODY</div>
                        <div className="bttn" >SKIN</div>
                        <div className="bttn" >HAIR</div> */}
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Home
