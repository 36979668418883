import "./Summary.css"
import {Link , useNavigate  } from "react-router-dom";
import { useState , useEffect , useContext} from "react";
import DataContext from "../DataContext"
import {IoMdTrash} from "react-icons/io"

function Summary() {

    let navigate  = useNavigate ();
    const Brand = process.env.REACT_APP_BRAND.toLocaleLowerCase();
    const {select , setSelect} = useContext(DataContext);
    const [coltext, setColtext] = useState("");
    
    useEffect(() => {
        if(Brand === "spa"){
            setColtext("#007c83")
        }else{
            setColtext("#00528d")
        }
        const arrayUniqueByKey = [...new Map(select.map(item => [item["uid_m_promotion"], item])).values()];
        setSelect(arrayUniqueByKey)
       
    },[]);   

    const onClickChange = (id) => {
        const data = select.filter(x => x.UUid !== id)
        setSelect(data)
    };

    if(select === ""){
        return (
            <div className="container product_wrapper">  
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status"></div>
                </div>
            </div>
        )
    }

    return (
        <>
            <section>
                <div className="summary_header">
                    <h1>รายการที่สนใจ</h1>
                </div>
                <div className="table-responsive ">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th width="30%">รายการ</th>
                                <th width="100%">Promotion Code</th>
                                <th width="100%">Promotion Name</th>
                                <th width="20%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {select.length === 0 ? 
                                <tr>
                                    <td width="100%">-</td>
                                    <td width="100%">ไม่มีรายการ</td>
                                    <td width="100%">-</td>
                                </tr>
                                :
                                select.map((x , index)=>{
                                    return(
                                        <tr key={x.UUid}>
                                            <td width="30%">{index + 1}</td>
                                            <td width="100%">{x.PROMOTION_CODE}</td>
                                            <td width="100%">{x.PROMOTION_NAME}</td>
                                            <td width="100%">
                                                <button type="button" className="btn btn-danger btn-sm" onClick={() => onClickChange(x.UUid)}><IoMdTrash/></button>
                                            </td>
                                        </tr>
                                )})                           
                            }                       
                        </tbody>
                    </table>
                </div>
                <div className="btn_wrapper mt-5">
                    <button type="button" className="submit-btn d-block" style={{backgroundColor: coltext}} onClick={() => navigate(-1)}>ย้อนกลับ</button>
                    {select.length === 0 ? "" :
                        <Link to="/information" className="d-inline-block">
                            <button type="button" className="submit-btn d-block" style={{backgroundColor: coltext}} >ระบุข้อมูลส่วนตัว</button>
                        </Link>  
                    }             
                </div>
            </section>
        </>
        
    );
  

}

export default Summary;