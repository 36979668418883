import '../Pages/Home.css'
import { Link } from "react-router-dom";
import DataContext from "../DataContext"
import { useContext } from "react"
import { FaClipboardList } from "react-icons/fa";


function Navbar () {
    
    const Brand = process.env.REACT_APP_BRAND.toLocaleLowerCase();
    const {select} = useContext(DataContext)
    return (
        <div>
            <div className='_navbar'>
                <Link to="/">
                    <div>  
                        <img
                            src={require(`../logo/${Brand}_logo.png`)}
                            style={{ width: "102px", height: "26px"}}
                            alt="Logo"
                        />
                    </div>
                </Link>
                <div>
                    <li><span></span></li>
                    <li><span></span></li>
                    <li><span></span></li>
                </div>
                <div className="btnwarper">
                    {select.length > 0 &&
                        <Link to="/summary" className="d-inline-block">                   
                            <div className='_navMenuIcon' style={{ position: "relative" }}>
                                <FaClipboardList/>
                                <span className='selectAlert' style={{fontSize:'0.5em'}}>{select.length}</span>                           
                            </div>
                        </Link>
                    }           
                    <Link to="/search" className="d-inline-block">
                        <div className='_navMenu'><li>ค้นหา Promotion</li></div>
                    </Link> 
                </div>         
            </div>             
        </div>
    )
}

export default Navbar
