import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import Swal from "sweetalert2";
import DataContext from "../DataContext"
import "./Information.css";
import MobileSelect from "mobile-select";

function Information() {
  const Brand = process.env.REACT_APP_BRAND.toLocaleLowerCase();
  const navigate = useNavigate();
  const {age , gender , setDataPromotion , dataPromotion , select} = useContext(DataContext);
  const tirggerRef = useRef(null);
  const [selectedVal, setSelectedVal] = useState(age);
  let msInstance = null;
  const [coltext, setColtext] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    gender: gender,
    age: selectedVal,
    weight: "",
    details: select,
  });

  useEffect(() => {
    if(dataPromotion.length === 0) {
      return navigate(`/`)
    }
    if (Brand === "spa") {
      setColtext("#007c83");
    } else {
      setColtext("#00528d");
    }
    let ages = [];

    for (var i = 1; i <= 100; i++) {
      ages.push(i);
    }
    if (!msInstance) {
      msInstance = new MobileSelect({
        wheels: [{ data: ages }],
        ensureBtnText: "ตกลง",
        cancelBtnText: "ยกเลิก",
        trigger: tirggerRef.current,
        triggerDisplayValue: false, // If you don't want to overwrite the HTML inside the trigger, you need to set this to false
        onChange: (data) => {
            var arrayOfNumbers = data.map(Number);
          setSelectedVal(arrayOfNumbers[0]);
          setData((prevProps) => ({
            ...prevProps,
            age: JSON.stringify(arrayOfNumbers[0]),
          }));
          setDataPromotion((prevProps) => ({
            ...prevProps,
            age: JSON.stringify(arrayOfNumbers[0]),
          }));
        },
      });
    }
    return () => {
      msInstance?.destroy();
      msInstance = null;
    };
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
    if(name === "gender"){
      setDataPromotion((prevProps) => ({
        ...prevProps,
        [name]: value,
      }));
    }
  };

  const submitForm = (e) => {

    e.preventDefault();  

    setIsLoading(true);

    var jsonData = JSON.stringify(data);
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL+"/save",
      headers: { 
        'api_key': process.env.REACT_APP_API_KEY, 
        'Content-Type': 'application/json'
      },
      data : jsonData
    };

    axios(config)
    .then(function (response) {
      if (response.status === 200) {
          setIsLoading(false)
          Swal.fire({
            icon: 'success',
            title: 'บันทึกสำเร็จ',
            showConfirmButton: false,
            timer: 2200
          }).then(function(){
              navigate(`/examplepdf` ,{
                state : response.data
              })
          });
      }else{
          throw new Error('Something went wrong');
      }     
    })
    .catch(function (error) {
      Swal.fire({
        icon: 'error',
        title: 'ลองใหม่อีกครั้ง',
        showConfirmButton: false,
        timer: 2000
      })
      console.log(error);
    });
    
  };


  

  return (
    <section className="get-in-touch">
      <h1 className="title" style={{ color: coltext }}>
        <img
          src={require(`../logo/${Brand}_logo.png`)}
          style={{ width: "300px", height: "80px" }}
          alt="Logo"
        />
      </h1>
      <form className="contact-form row" onSubmit={submitForm}>
        <div className="form-field col-lg-6">
          <label
            className="label"
            htmlFor="first_name"
            style={{ color: coltext }}
          >
            ชื่อ
          </label>
          <input
            id="first_name"
            name="first_name"
            className="input-text js-input"
            type="text"
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-field col-lg-6 ">
          <label
            className="label"
            htmlFor="last_name"
            style={{ color: coltext }}
          >
            นามสกุล
          </label>
          <input
            id="last_name"
            name="last_name"
            className="input-text js-input"
            type="text"
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-field col-lg-6 ">
          <label
            className="label"
            htmlFor="mobile_number"
            style={{ color: coltext }}
          >
            เบอร์โทร
          </label>
          <input
            id="mobile_number"
            name="mobile_number"
            pattern="^-?[0-9]\d*\.?\d*$"
            className="input-text js-input"
            type="number"
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-field col-lg-6 radio_input">
          <div className="label mb-3" htmlFor="tbw" style={{ color: coltext }}>
            เพศ 
          </div>    
          <div className="row">
            <div className="col-6">
              <input type="radio" name="gender" value="M" id="radio-16" defaultChecked={dataPromotion.gender === "M"} onChange={handleInputChange}/>
              <label htmlFor="radio-16">ชาย</label>
            </div>
            <div className="col-6">
              <input type="radio" name="gender" value="F" id="radio-17" defaultChecked={dataPromotion.gender === "F"} onChange={handleInputChange}/>
              <label htmlFor="radio-17">หญิง</label>
            </div>
          </div>
        </div>

        <div className="form-field col-lg-6">
            <label className="label" htmlFor="age" style={{ color: coltext }}>
                อายุ
            </label>
            <div className="ms-default-trigger ageclass" ref={tirggerRef}>
                <div className="">
                    {selectedVal ? selectedVal + " ปี" : "กรุณาเลือก..."}
                </div>               
            </div>
        </div>
        <div className="form-field col-lg-6 ">
          <label className="label" htmlFor="weight" style={{ color: coltext }}>
            น้ำหนัก
          </label>
          <input
            id="weight"
            name="weight"
            className="input-text js-input"
            type="number"
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-field col-lg-12 d-flex justify-content-between">
          {!isLoading ? (
            <input
              className="submit-btn"
              style={{ backgroundColor: coltext }}
              type="submit"
              value="บันทึก"
            />
          ) : (
            <button
              className="submit-btn"
              style={{ backgroundColor: coltext }}
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          )}

          <Link to="/">
            <input
              className="submit-btn bg-danger"
              type="submit"
              value="ยกเลิก"
              disabled={isLoading}
            />
          </Link>
        </div>
      </form>
    </section>
  );
}

export default Information;
